import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import RadioButton from "../../components/Inputs/RadioButton";
import { initiateCheckoutSocket, disconnectCheckoutSocket, subscribeToCheckout } from "../../hooks/checkoutSocketClient";
const paymentMethodText = {
  spei: 'con Transferencia',
  credit: 'con Crédito',
  cod: 'Contra Entrega'
}

const OrderPaymentStatusEnum = {
  PENDING: 1,
  ACCEPTED: 2,
  CANCELED: 3,
  BANK_REJECT: 4,
  BANK_APPROVE: 5
}

const OrderPaymentMethodEnum = {
  COD: 1,
  PRE_PAID_BNPL: 2,
  SPEI: 3
}

const Checkout = ({
  reduxGetPreOrder,
  reduxValidateCredit,
  reduxUpdatePayment,
  preOrder,
  validatePreOrder,
  requestingFetchPreOrder,
  successfulFetchPreOrder,
  errorFetchPreOrder,
  requestingFetchValidatePreOrder,
  successfulFetchValidatePreOrder,
  errorsFetchValidatePreOrder,
  errorFetchCodPayment,
  requestingFetchCodPayment,
  successfulFetchCodPayment,
  codPayment,
  reduxShowLoading,
  reduxHideLoading
}) => {
  const [endProcess, setEndProcess] = useState(false);
  const [paymentMethodUpdated, setPaymentMethodUpdated] = useState(false);
  const [checkoutStatus, setCheckoutStatus] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { idOrder } = useParams();
  const modalForm = useForm({
    defaultValues: {
      paymentMethod: "spei",
    },
  });

  useEffect(() => {
    reduxGetPreOrder({ hashOrder: idOrder });
  }, [idOrder]);

  useEffect(() => {
    if (Object.keys(preOrder).length>0) {
      if(
        preOrder?.order?.idPaymentMethod === OrderPaymentMethodEnum.COD ||
        preOrder?.order?.payment?.length === 0
      ) return;

      if(preOrder?.order?.idPaymentMethod === OrderPaymentMethodEnum.SPEI) {
        setPaymentMethodUpdated('spei');
        setEndProcess(true);
        return;
      }

      if (preOrder?.order?.status === OrderPaymentStatusEnum.PENDING) {
        initiateCheckoutSocket(idOrder);
        subscribeToCheckout(handleSocketMessage, 'checkoutStatus');
        reduxShowLoading();
        Swal.fire({
          title: "Tu solicitud se esta procesando!",
          icon: "info",
          confirmButtonText: "Aceptar",
        });
        return;
      }

      if (preOrder?.order?.status === OrderPaymentStatusEnum.ACCEPTED || preOrder?.order?.status === OrderPaymentStatusEnum.CANCELED) {
        setPaymentMethodUpdated('credit');
        setEndProcess(true);
        setCheckoutStatus(preOrder?.order?.status === OrderPaymentStatusEnum.ACCEPTED ? 'accepted' : 'canceled');
        return;
      }

      if (preOrder?.order?.status === OrderPaymentStatusEnum.BANK_REJECT || preOrder?.order?.status === OrderPaymentStatusEnum.BANK_APPROVE) {
        setCheckoutStatus(preOrder?.order?.status === OrderPaymentStatusEnum.BANK_APPROVE ? 'true' : 'false');
        return;
      }
    }
  }, [preOrder]);

  const handlePaymentMethod = (paymentMethod) => {
    setPaymentMethod(paymentMethod);
    modalForm.setValue('paymentMethod', paymentMethod);
  }

  const handleValidateCredit = () => {
    reduxValidateCredit({  hashOrder: idOrder, paymentMethod: modalForm.getValues('paymentMethod') });
  };

  const handleCreditProcess = (url) => {
    window.open(url, '_blank');
  };
  
  const handleCODoption = (paymentMethod) => {
    reduxUpdatePayment({ hashOrder: idOrder, paymentMethod: paymentMethod });
    setPaymentMethodUpdated(paymentMethod);
    setEndProcess(true);
  };

  const handleSocketMessage = (error, message) => {
    reduxShowLoading();
    setCheckoutStatus(message);
  }

  useEffect(() => {
    if (Object.keys(validatePreOrder).length>0) {
      if (validatePreOrder?.payment_method?.type==='lending') {
        initiateCheckoutSocket(idOrder);
        setPaymentMethodUpdated('credit');
        subscribeToCheckout(handleSocketMessage, 'checkoutStatus');
        handleCreditProcess(validatePreOrder?.payment_method?.callbackUrl);
        Swal.fire({
          title: "Tu solicitud de crédito está siendo procesada!",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        return;
      }
      
      if (validatePreOrder?.payment_method?.type === 'bank_transfer') {
        window.open(`https://sandbox-dashboard.openpay.mx/spei-pdf/mvc7gkgux6o5ghcjxizm/${validatePreOrder?.id}`, '_blank');
        setPaymentMethodUpdated('spei');
        setEndProcess(true);
        Swal.fire({
          title: "Recibo de pago generado!",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        return;
      }

      Swal.fire({
        title: "Información",
        text: "No se pudo procesar tu solicitud, desea continuar con pago contra entrega?",
        icon: "question",
        // showCancelButton: true,
        confirmButtonText: "Pago Contra Entrega",
        // cancelButtonText: "Reintentar",
      }).then((result) => {
        if (result.isConfirmed) {
          handleCODoption('cod');
        }
        // else if (result.isDismissed) {
        //   window.location.reload();
        // }
        validatePreOrder=null;
      });
    }
  }, [validatePreOrder]);

  useEffect(() => {
    if (checkoutStatus === 'true') {
      // reduxHideLoading();
      disconnectCheckoutSocket();
      Swal.fire({
        title: "Buenas noticias!",
        text: "Tu solicitud de crédito fue aprobada.\n\r¿Deseas continuar con esta opción para tu compra?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Usar Crédito",
        cancelButtonText: "Pago Contra Entrega",
      }).then((result) => {
        if (result.isConfirmed) {
          handleCODoption('credit');
        } else if (result.isDismissed) {
          handleCODoption('cod');
        }
        setEndProcess(true);
        validatePreOrder=null;
      });
    }

    if (checkoutStatus === 'false') {
      reduxHideLoading();
      disconnectCheckoutSocket();
      Swal.fire({
        title: "Información",
        text: "No se pudo procesar tu solicitud, desea continuar con pago contra entrega?",
        icon: "question",
        confirmButtonText: "Pago Contra Entrega",
      }).then((result) => {
        if (result.isConfirmed) {
          handleCODoption('cod');
          setEndProcess(true);
        }
        validatePreOrder=null;
      });
    }
  }, [checkoutStatus]);

  if (errorFetchPreOrder) {
    return <h3>Error al cargar la orden...</h3>;
  }
  if (requestingFetchPreOrder) {
    return <h3>Cargando la orden...</h3>;
  }
  if (!successfulFetchPreOrder) {
    return <h3>Orden no encontrada</h3>;
  }
  if (endProcess) {
    return (
      <div>
      <div id="wrapper d-flex flex-column">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <div className="container-fluid">
        
      <div className="row vh-100 justify-content-center align-items-center">
      
        <div className="col-xl-10 col-lg-12 col-md-9">
          <img height="100" class="img-responsive" src="https://app.kikilogistics.co/logorgb_kiki.svg" />
          <div className="card o-hidden border-0 shadow-lg my-5" style={{background:"#424242"}}>
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-9">
                    {paymentMethodUpdated === 'credit' && (
                      <h3 className="mb-3">Proceso <b>
                        { checkoutStatus === 'true'?'Exitoso':checkoutStatus==='accepted'?'Aceptado':'Cancelado' }
                      </b></h3>
                    )}
                    {paymentMethodUpdated === 'spei' && (<h3 className="mb-3">{preOrder?.order?.status === OrderPaymentStatusEnum.BANK_APPROVE ? 'Pago Exitoso' : 'Pago Pendiente'}</h3>)}
                    <hr/>
                    <>
                      <h4 className="mb-3">Gracias por su compra!</h4>
                      <h5 className="mb-3">Orden { preOrder?.idOrder }</h5>
                      <h5 className="mb-3">Pago {paymentMethodText[paymentMethodUpdated]}</h5>
                  </>
                  <h4 className="mb-3">Hasta pronto!</h4>
                  </div>
                <div className="col-lg-3 justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="96" width="92" viewBox="0 0 448 512"><path fill="#28c908" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div></div></div></div>);
  }
  return (
    <div> 
      <div id="wrapper d-flex flex-column">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content" >
            <div className="container-fluid">
            
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
        <img height="100" class="img-responsive" src="https://app.kikilogistics.co/logorgb_kiki.svg" />
          <div className="card o-hidden border-0 shadow-lg my-5" style={{background:"#424242"}}>
            <div className="card-body p-4">
              <div className="row">
                {successfulFetchPreOrder && Object.keys(preOrder).length>1 ? (<>
                  <div className="col-lg-7">
                    <h5 className="mb-3"><a href="#!" className="text-body">Checkout Orden {preOrder && Object.keys(preOrder).length > 0 ? preOrder.idOrder : ''}</a></h5>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div>
                        <p className="mb-1">Detalle </p>
                      </div>
                    </div>
                    {preOrder?.order?.orderDetails.map((detail) =>
                      <div className="card mb-3">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              <div className="ms-3">
                                <h5>{detail.product.name}</h5>
                                <p className="small mb-0">{detail.quantity} x $ {detail.product.price}</p>
                              </div>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                              <div>
                                <h5 className="mb-0">$ {detail.price}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)}
                  </div>
                  <div className="col-lg-5">
                    <div className="card bg-primary text-white rounded-3">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <h5 className="mb-0">Su información</h5>
                        </div>
                        <div data-mdb-input-init className="form-outline form-white mb-4">
                          <label className="form-control form-control">{preOrder?.firstName + ' ' + preOrder?.lastName}</label>
                          <label className="form-control form-control">{preOrder?.reference1}</label>
                          <label className="form-control form-control">{preOrder?.order?.email}</label>
                        </div>
                        <div data-mdb-input-init className="form-outline form-white mb-4">
                          <label className="form-control form-control">{preOrder?.address}</label>
                        </div>
                        <hr className="my-4" />
                        <div className="d-flex justify-content-between mb-4">
                          <p className="mb-2">Total</p>
                          <p className="mb-2">{preOrder?.order?.currency}$ {preOrder?.order?.ammount}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                          <p className="mb-2">Metodos de pago</p>
                          <p className="mb-2">
                            <Controller
                              control={modalForm.control}
                              name="paymentMethod"
                              render={({ field }) => (
                                <>
                                  <RadioButton 
                                    options={[{name: 'Transferencia', value: 'spei'}, {name: 'Crédito', value: 'credit'}]} 
                                    fieldController={{onChange: handlePaymentMethod, value: field.value}} />
                                </>
                                  )}
                              />
                          </p>
                        </div>
                        <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn bg-default btn-block btn-lg"
                          onClick={handleValidateCredit}>
                          <div className="d-flex justify-content-between">
                            <span>{preOrder?.order?.currency}$ {preOrder?.order?.ammount}</span>
                            <span> Pagar <i className="fas fa-long-arrow-alt-right ms-2"></i></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <a className="text-white" href={`https://dev-img.kikilogistics.co/T%26C+Openpay+-+Kiki+V1.pdf`} target="_blank" rel="noreferrer">Terminos y condiciones</a>
                  </div>
                </>) : (<><div className="col-lg-7">
                    <h3 className="mb-3">Orden no encontrada!</h3>
                    <hr /></div></>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div></div></div></div>
  );
};
const mapStateToProps = (state) => {
  return {
    preOrder: state.orderState.preOrder,
    validatePreOrder: state.orderState.validatePreOrder,
    codPayment: state.orderState.codPayment,
    requestingFetchPreOrder: state.orderState.requestingFetchPreOrder,
    successfulFetchPreOrder: state.orderState.successfulFetchPreOrder,
    errorFetchPreOrder: state.orderState.errorFetchPreOrder,
    requestingFetchValidatePreOrder: state.orderState.requestingFetchValidatePreOrder,
    successfulFetchValidatePreOrder: state.orderState.successfulFetchValidatePreOrder,
    errorsFetchValidatePreOrder: state.orderState.errorsFetchValidatePreOrder
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetPreOrder: (payload) =>
      dispatch({
        type: "FETCH_PREORDER_REQUEST",
        value: payload,
      }),
      reduxValidateCredit: (payload) =>
        dispatch({
          type: "FETCH_VALIDATE_REQUEST",
          value: payload,
        }),
      reduxUpdatePayment: (payload) =>
        dispatch({
          type: "FETCH_UPDATE_PAYMENT_REQUEST",
          value: payload,
        }),
      reduxShowLoading: (payload) =>
        dispatch({
          type: "SHOW_LOADING",
          value: payload,
        }),
      reduxHideLoading: (payload) =>
        dispatch({
          type: "HIDE_LOADING",
          value: payload,
        }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);