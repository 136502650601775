import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Customer from "./pages/Customer";
import Category from "./pages/Category";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Country from "./pages/Country";
import Department from "./pages/Department";
import Routing from "./pages/Routing";
import City from "./pages/City";
import Role from "./pages/Role";
import Area from "./pages/Area";
import Zone from "./pages/Zone";
import Neighborhood from "./pages/Neighborhood";
import PublicRoute from "./hooks/PublicRoute";
import PrivateRoute from "./hooks/PrivateRoute";
import Company from "./pages/Company";
import Permission from "./pages/Permission";
import User from "./pages/User";
import Address from "./pages/Address";
import AssignAddresses from "./pages/AssignAddresses";
import AddressGenerator from "./pages/AddressGenerator";
import AddressValidator from "./pages/AddressValidator";
import Assignments from "./pages/Assignments";
import ZoneNeighborhood from "./pages/ZoneNeighborhood";
import Novelty from "./pages/Novelties";
import Headquarters from "./pages/Headquarters";
import RecordsReport from "./pages/Reports/RecordsReport";
import ForgotPassword from "./pages/ForgotPassword";
import Lot from "./pages/Lot";
import Product from "./pages/Product";
import Order from "./pages/Order";
import LotProduct from "./pages/LotProduct";
import OrderAssigned from "./pages/OrderAssigned";
import Collect from "./pages/Collect";
import ColectsReport from "./pages/Reports/CollectsReport";
import AssignOperator from "./pages/AssignOperator";
import Tracking from "./pages/Tracking";
import MappingCity from "./pages/MappingCity";
import MappingCustomer from "./pages/MappingCustomer";
import PdfTracking from "./pages/PdfTracking";
import SessionsReport from "./pages/Reports/SessionsReport";
import Note from "./pages/Note";
import AddressCardinal from "./pages/AddressCardinal";
import AddressInsideType from "./pages/AddressInsideType";
import AddressNomenclature from "./pages/AddressNomenclature";
import AddressRoadType from "./pages/AddressRoadType";
import ConciliationsReport from "./pages/Reports/ConciliationsReport";
import AddressCloseConfirmation from "./pages/AddressCloseConfirmation/index";
import AddressFinishedReport from "./pages/Reports/AddressFinished/index";
import Rescheduling from "./pages/Rescheduling";
import ReschedulingsReport from "./pages/Reports/ReschedulingsReport";
import UpdateStates from "./pages/UpdateStates";
import OrdersLocked from "./pages/OrdersLocked";
import CollectConfirm from "./pages/CollectConfirm";
import ConfirmCollectionReport from "./pages/Reports/ConfirmCollectionReport";
import MessagebirdReport from "./pages/Auditoria/messagebird/messageBirdReport";
import Branch from "./pages/Branch";
import CartaPorteIndex from "./pages/Auditoria/cartaPorte";
import Statuses from "./pages/Statuses"
import Warehouse from "./pages/Warehouse";
import ConfirmCollectionShipperReport from "./pages/Reports/ConfirmCollectionReport/ForShipper";
import Inventory from "./pages/Inventory";
import Coverage from "./pages/Coverage";
import NewEdit from "./pages/Coverage/NewEdit";
import Alerts from "./pages/Alerts";
import WmsOrders from "./pages/WMS/Order";
import DspOrder from "./pages/DSP/Order";
import AssignCourierOrders from "./pages/DSP/AssignCourierOrders";
import WmsScanner from "./pages/WMS/Scanner"
import AssignOrders from "./pages/DSP/AssignOrders";
import Welcome from "./pages/Welcome";
import DspScanner from "./pages/DSP/ScannerDsp";
import Receivings from "./pages/WMS/Incomings/Receivings";
import Incomings from "./pages/WMS/Incomings";
import Returns from "./pages/WMS/Incomings/Returns";
import Relocation from "./pages/WMS/Incomings/Relocation";
import RelocationWms from "./pages/WMS/Relocation";
import ReturnsProcess from "./pages/WMS/Incomings/Returns/Process";
import ReturnsEntry from "./pages/WMS/Incomings/Returns/Entry";
import Checkout from "./pages/Checkout";

/**
      <PublicRoute path="/" exact restricted={true} component={SignIn} />
      <PrivateRoute
        exact
        path="/welcome"
        component={Welcome}
      />
      <PrivateRoute
        exact
        path="/alerts"
        component={Alerts}
        when="feature:alerts-module-show"
      />
      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
        when="feature:see-dashboard"
      />
      <PrivateRoute
        exact
        path="/routing"
        component={Routing}
        when="feature:see-routes"
      />
      <PrivateRoute
        exact
        path="/countries"
        component={Country}
        when="feature:read-country"
      />
      <PrivateRoute
        exact
        path="/departments"
        component={Department}
        when="feature:read-department"
      />
      <PrivateRoute
        exact
        path="/cities"
        component={City}
        when="feature:read-city"
      />
      <PrivateRoute
        exact
        path="/areas"
        component={Area}
        when="feature:read-area"
      />
      <PrivateRoute
        exact
        path="/zones"
        component={Zone}
        when="feature:read-zone"
      />
      <PrivateRoute
        exact
        path="/neighborhoods"
        component={Neighborhood}
        when="feature:read-neighborhood"
      />
      <PrivateRoute
        exact
        path="/addresses"
        component={Address}
        when="feature:read-address"
      />
      <PrivateRoute
        exact
        path="/roles"
        component={Role}
        when="feature:read-role"
      />
      <PrivateRoute
        exact
        path="/permissions"
        component={Permission}
        when="feature:read-permission"
      />
      <PrivateRoute
        exact
        path="/users"
        component={User}
        when="feature:read-user"
      />
      <PrivateRoute
        exact
        path="/companies"
        component={Company}
        when="feature:read-company"
      />
      <PrivateRoute
        exact
        path="/assignaddress"
        component={AssignAddresses}
        when="feature:assign-addresses"
      />
      <PrivateRoute
        exact
        path="/generate"
        component={AddressGenerator}
        when="feature:generate-addresses"
      />
        <PrivateRoute
        exact
        path="/validate"
        component={AddressValidator}
        when="feature:validate-addresses"
      />
      <PrivateRoute
        exact
        path="/assignments"
        component={Assignments}
        when="feature:assignments-addresses"
      />
      <PrivateRoute
        exact
        path="/zoneneighborhoods"
        component={ZoneNeighborhood}
        when="feature:read-zoneneighborhood"
      />
      <PrivateRoute
        exact
        path="/novelties"
        component={Novelty}
        when="feature:read-record"
      />
      <PrivateRoute
        exact
        path="/headquarters"
        component={Headquarters}
        when="feature:read-headquarters"
      />
      <PrivateRoute
        exact
        path="/recordsReport"
        component={RecordsReport}
        when="feature:read-recordsReport"
      />
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      <PrivateRoute
        exact
        path="/lots"
        component={Lot}
        when="feature:read-lot"
      />
      <PrivateRoute
        exact
        path="/products"
        component={Product}
        when="feature:read-product"
      />
      <PrivateRoute
        exact
        path="/orders"
        component={Order}
        when="feature:read-order"
      />
      <PrivateRoute
        exact
        path="/customers"
        component={Customer}
        when="feature:read-customer"
      />
      <PrivateRoute
        exact
        path="/categories"
        component={Category}
        when="feature:read-category"
      />
      <PrivateRoute
        exact
        path="/lotproducts"
        component={LotProduct}
        when="feature:read-lotproduct"
      />
      <PrivateRoute
        exact
        path="/ordersAssigned"
        component={OrderAssigned}
        when="feature:read-order"
      />
      <PrivateRoute
        exact
        path="/collects"
        component={Collect}
        when="feature:read-collect"
      />
      <PrivateRoute
        exact
        path="/collectsReport"
        component={ColectsReport}
        when="feature:read-collectsReport"
      />
      <PrivateRoute
        exact
        path="/assignOperator"
        component={AssignOperator}
        when="feature:assign-operator"
      />
      <PrivateRoute
        exact
        path="/mappingCity"
        component={MappingCity}
        when="feature:read-mapping-cities"
      />
      <PrivateRoute
        exact
        path="/mappingCustomer"
        component={MappingCustomer}
        when="feature:read-mapping-customers"
      />
      <PrivateRoute
        exact
        path="/pdf-tracking"
        component={PdfTracking}
        when="feature:generate-pdf-tracking"
      />
      <PrivateRoute
        exact
        path="/sessionsReport"
        component={SessionsReport}
        when="feature:read-sessions-report"
      />
      <PrivateRoute
        exact
        path="/notes"
        component={Note}
        when="feature:read-note"
      />
      <PublicRoute
        exact
        path="/addressCardinal"
        component={AddressCardinal}
        when="feature:read-addressCardinalPoints"
      />
      <PublicRoute
        exact
        path="/addressInsideType"
        component={AddressInsideType}
        when="feature:read-addressInsideType"
      />
      <PublicRoute
        exact
        path="/addressNomenclature"
        component={AddressNomenclature}
        when="feature:read-addressNomenclature"
      />
      <PublicRoute
        exact
        path="/addressRoadType"
        component={AddressRoadType}
        when="feature:read-addressRoadType"
      />
      <PrivateRoute
        exact
        path="/addressCloseConfirmation"
        component={AddressCloseConfirmation}
        when={"feature:show-closeConfirmation"}
      />
      <PrivateRoute
        exact
        path="/addressFinishedReport"
        component={AddressFinishedReport}
        when={"feature:show-closeConfirmation-report"}
      />
      <PrivateRoute
        exact
        path="/conciliationsReport"
        component={ConciliationsReport}
        when="feature:read-conciliation-report"
      />
      <PrivateRoute
        exact
        path="/rescheduling"
        component={Rescheduling}
        when="feature:show-rescheduling"
      />
      <PrivateRoute
        exact
        path="/reschedulingReport"
        component={ReschedulingsReport}
        when="feature:show-rescheduling-report"
      />
      <PrivateRoute
        exact
        path="/statesUpdate"
        component={UpdateStates}
        when="feature:update-states"
      />
      <PrivateRoute
        exact
        path="/ordersLocked"
        component={OrdersLocked}
        when="feature:read-orders-locked"
      />
      <PrivateRoute
        exact
        path="/ConfirmCollect"
        component={CollectConfirm}
        when="feature:show-collectconfirm"
      />
      <PrivateRoute
        exact
        path="/messageReport"
          component={MessagebirdReport}
        when="feature:show-collectconfirm"
      />
      <PrivateRoute
        exact
        path="/statuses"
          component={Statuses}
        when="feature:show-collectconfirm"
      />
      <PrivateRoute
        exact
        path="/cartaPorteLog"
        component={CartaPorteIndex}
        when="feature:show-collectconfirm"
      />
      <PrivateRoute
        exact
        path="/confirmCollectionReport"
        component={ConfirmCollectionReport}
        when="feature:show-collectconfirm-report"
      />
      <PrivateRoute
        exact
        path="/confirmCollectionShipperReport"
        component={ConfirmCollectionShipperReport}
        whenMultiple={["feature:report-confirmcollection-shipper-show", "feature:isClient"]}
      />
      <PublicRoute exact path="/tracking" component={Tracking} />
      <PublicRoute exact path="/tracking/:idTracking" component={Tracking} />
      <PrivateRoute
        exact
        path="/branch"
        component={Branch}
        when="feature:show-branch"
      />
      <PrivateRoute
        exact
        path="/warehouse"
        component={Warehouse}
        when="feature:warehouse-show-module"
      />
      <PrivateRoute
        exact
        path="/inventory"
        component={Inventory}
        when="feature:wms-inventory-module-show"
      />
      <PrivateRoute
        exact
        path="/coverage"
        component={Coverage}
        when="feature:coverage-show-module"
      />
      <PrivateRoute
        exact
        path="/coverage/NewEdit"
        component={NewEdit}
        when="feature:coverage-show-module"
      />
      <PrivateRoute
        exact
        path="/WMS/Order"
        component={WmsOrders}
        when="feature:wms-orders-show"
      />
      <PrivateRoute
        exact
        path="/DSP/Order"
        component={DspOrder}
        when="feature:dsp-show-module"
      />
      <PrivateRoute
        exact
        path="/DSP/assignCourier"
        component={AssignCourierOrders}
        when="feature:dsp-show-module"
      />
      <PrivateRoute
        exact
        path="/DSP/assignOrders"
        component={AssignOrders}
        when="feature:dsp-show-module"
      />
      <PrivateRoute
        exact
        path="/wms/incomings"
        component={Incomings}
        when="feature:wms-inventory-incomings-module-show"
      />
      <PrivateRoute
        exact
        path="/wms/receiving"
        component={Receivings}
        when="feature:wms-inventory-receivings-process"
      />
      <PrivateRoute
        exact
        path="/wms/pending-returns"
        component={Returns}
        when="feature:wms-inventory-returns-process"
      />
      <PrivateRoute
        exact
        path="/wms/pending-returns/entry"
        component={ReturnsEntry}
        when="feature:wms-inventory-returns-process"
      />  
      <PrivateRoute
        exact
        path="/wms/pending-returns/process/:idReturn"
        component={ReturnsProcess}
        when="feature:wms-inventory-returns-process"
      />
      <PrivateRoute
        exact
        path="/wms/incomingRelocations"
        component={Relocation}
        when="feature:wms-inventory-relocation-process"
      />
      <PrivateRoute
        exact
        path="/wms/relocation"
        component={RelocationWms}
        
      />
      <Route
        exact
        path="/DSP/Scanner"
        component={DspScanner}
      />
      <Route
        exact
        path="/WMS/Scanner"
        component={WmsScanner}
      /> 
**/

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/checkout/:idOrder" component={Checkout} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;