const initialState = {
  requestingFetchOrders: false,
  successfulFetchOrders: false,
  errorFetchOrders: false,
  orders: {},
  requestingCreateOrder: false,
  successfulCreateOrder: false,
  errorsCreateOrder: false,
  order: null,
  requestingReadOrder: false,
  successfulReadOrder: false,
  errorsReadOrder: false,
  rowEdited: null,
  requestingDeleteOrder: false,
  successfulDeleteOrder: false,
  errorsDeleteOrder: false,
  rowDeleted: null,
  requestingUpdateOrder: false,
  successfulUpdateOrder: false,
  errorsUpdateOrder: false,
  rowUpdated: null,
  requestingFetchOrdersByUser: false,
  successfulFetchOrdersByUser: false,
  errorFetchOrdersByUser: false,
  ordersByUser: {},
  requestingFetchOrdersAssigned: false,
  successfulFetchOrdersAssigned: false,
  errorFetchOrdersAssigned: false,
  ordersAssigned: {},
  requestingDeleteSoftOrder: false,
  successfulDeleteSoftOrder: false,
  errorsDeleteSoftOrder: false,
  rowDeletedSoft: null,
  requestingFetchOrderForReturn: false,
  successfulFetchOrderForReturn: false,
  errorsFetchOrderForReturn: false,
  orderForReturn: null,
  errorFetchPreOrder: false,
  requestingFetchPreOrder: false,
  successfulFetchPreOrder: false,
  preOrder: {},
  errorFetchValidatePreOrder: false,
  requestingFetchValidatePreOrder: false,
  successfulFetchValidatePreOrder: false,
  validatePreOrder:{},
  errorFetchCodPayment: false,
  requestingFetchCodPayment: false,
  successfulFetchCodPayment: false,
  codPayment: {}
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ORDERS_REQUESTING":
      return {
        ...state,
        requestingFetchOrders: true,
        successfulFetchOrders: false,
        errorsFetchOrders: false,
      };
    case "FETCH_ORDER_SUCCESS":
      return {
        ...state,
        errorFetchOrders: false,
        requestingFetchOrders: false,
        successfulFetchOrders: true,
        orders: action.value,
      };
    case "FETCH_ORDER_ERROR":
      return {
        ...state,
        errorFetchOrders: true,
        requestingFetchOrders: false,
        successfulFetchOrders: false,
      };
    case "CREATE_ORDER_REQUESTING":
      return {
        ...state,
        requestingCreateOrder: true,
        successfulCreateOrder: false,
        errorsCreateOrder: false,
      };
    case "CREATE_ORDER_SUCCESS":
      return {
        ...state,
        errorsCreateOrder: false,
        requestingCreateOrder: false,
        successfulCreateOrder: true,
        order: action.value,
      };
    case "CREATE_ORDER_ERROR":
      return {
        ...state,
        errorsCreateOrder: true,
        requestingCreateOrder: false,
        successfulCreateOrder: false,
      };
    case "READ_ORDER_REQUESTING":
      return {
        ...state,
        requestingReadOrder: true,
        successfulReadOrder: false,
        errorsReadOrder: false,
      };
    case "READ_ORDER_SUCCESS":
      return {
        ...state,
        errorsReadOrder: false,
        requestingReadOrder: false,
        successfulReadOrder: true,
        rowEdited: action.value,
      };
    case "READ_ORDER_ERROR":
      return {
        ...state,
        errorsReadOrder: true,
        requestingReadOrder: false,
        successfulReadOrder: false,
      };
    case "DELETE_ORDER_REQUESTING":
      return {
        ...state,
        requestingDeleteOrder: true,
        successfulDeleteOrder: false,
        errorsDeleteOrder: false,
      };
    case "DELETE_ORDER_SUCCESS":
      return {
        ...state,
        errorsDeleteOrder: false,
        requestingDeleteOrder: false,
        successfulDeleteOrder: true,
        rowDeleted: action.value,
      };
    case "DELETE_ORDER_ERROR":
      return {
        ...state,
        errorsDeleteOrder: true,
        requestingDeleteOrder: false,
        successfulDeleteOrder: false,
      };
    case "UPDATE_ORDER_REQUESTING":
      return {
        ...state,
        requestingUpdateOrder: true,
        successfulUpdateOrder: false,
        errorsUpdateOrder: false,
      };
    case "UPDATE_ORDER_SUCCESS":
      return {
        ...state,
        errorsUpdateOrder: false,
        requestingUpdateOrder: false,
        successfulUpdateOrder: true,
        rowUpdated: action.value,
      };
    case "UPDATE_ORDER_ERROR":
      return {
        ...state,
        errorsUpdateOrder: true,
        requestingUpdateOrder: false,
        successfulUpdateOrder: false,
      };
    case "FETCH_ORDERSBYUSER_REQUESTING":
      return {
        ...state,
        requestingFetchOrdersByUser: true,
        successfulFetchOrdersByUser: false,
        errorFetchOrdersByUser: false,
      };
    case "FETCH_ORDERBYUSER_SUCCESS":
      return {
        ...state,
        errorFetchOrdersByUser: false,
        requestingFetchOrdersByUser: false,
        successfulFetchOrdersByUser: true,
        ordersByUser: action.value,
      };
    case "FETCH_ORDERBYUSER_ERROR":
      return {
        ...state,
        errorFetchOrdersByUser: true,
        requestingFetchOrdersByUser: false,
        successfulFetchOrdersByUser: false,
      };
    case "FETCH_ORDERSASSIGNED_REQUESTING":
      return {
        ...state,
        requestingFetchOrdersAssigned: true,
        successfulFetchOrdersAssigned: false,
        errorFetchOrdersAssigned: false,
      };
    case "FETCH_ORDERASSIGNED_SUCCESS":
      return {
        ...state,
        errorFetchOrdersAssigned: false,
        requestingFetchOrdersAssigned: false,
        successfulFetchOrdersAssigned: true,
        ordersAssigned: action.value,
      };
    case "FETCH_ORDERASSIGGNED_ERROR":
      return {
        ...state,
        errorFetchOrdersAssigned: true,
        requestingFetchOrdersAssigned: false,
        successfulFetchOrdersAssigned: false,
      };
    case "DELETE_SOFT_ORDER_REQUESTING":
      return {
        ...state,
        requestingDeleteSoftOrder: true,
        successfulDeleteSoftOrder: false,
        errorsDeleteSoftOrder: false,
      };
    case "DELETE_SOFT_ORDER_SUCCESS":
      return {
        ...state,
        errorsDeleteSoftOrder: false,
        requestingDeleteSoftOrder: false,
        successfulDeleteSoftOrder: true,
        rowDeletedSoft: action.value,
      };
    case "DELETE_SOFT_ORDER_ERROR":
      return {
        ...state,
        errorsDeleteSoftOrder: true,
        requestingDeleteSoftOrder: false,
        successfulDeleteSoftOrder: false,
      };
    case "RESET_ORDER_FORM":
      return {
        ...state,
        requestingCreateOrder: false,
        successfulCreateOrder: false,
        errorsCreateOrder: false,
        order: null,
        requestingReadOrder: false,
        successfulReadOrder: false,
        errorsReadOrder: false,
        rowEdited: null,
        requestingDeleteOrder: false,
        successfulDeleteOrder: false,
        rowDeleted: null,
        requestingUpdateOrder: false,
        successfulUpdateOrder: false,
        errorsUpdateOrder: false,
        rowUpdated: null,
        requestingFetchOrdersByUser: false,
        successfulFetchOrdersByUser: false,
        errorFetchOrdersByUser: false,
        ordersByUser: {},
        requestingFetchOrdersAssigned: false,
        successfulFetchOrdersAssigned: false,
        errorFetchOrdersAssigned: false,
        ordersAssigned: {},
        requestingDeleteSoftOrder: false,
        successfulDeleteSoftOrder: false,
        errorsDeleteSoftOrder: false,
        rowDeletedSoft: null,
      };
    case "RESET":
      return initialState;
      case "FETCH_PREORDER_REQUEST":
        return {
          ...state,
          requestingFetchPreOrder: true,
          successfulFetchPreOrder: false,
          errorFetchPreOrder: false,
        };
      case "FETCH_PREORDER_SUCCESS":
        return {
          ...state,
          errorFetchPreOrder: false,
          requestingFetchPreOrder: false,
          successfulFetchPreOrder: true,
          preOrder: action.value,
        };
      case "FETCH_PREORDER_ERROR":
        return {
          ...state,
          errorFetchPreOrder: true,
          requestingFetchPreOrder: false,
          successfulFetchPreOrder: false,
        };
      case "FETCH_VALIDATE_REQUEST":
        return {
          ...state,
          requestingFetchValidatePreOrder: true,
          successfulFetchValidatePreOrder: false,
          errorsFetchValidatePreOrder: false,
        };
      case "FETCH_VALIDATE_SUCCESS": 
        return {
          ...state,
          errorsFetchValidatePreOrder: false,
          requestingFetchValidatePreOrder: false,
          successfulFetchValidatePreOrder: true,
          validatePreOrder: action.value,
        };
      case "FETCH_VALIDATE_ERROR":
        return {
          ...state,
          errorsFetchValidatePreOrder: true,
          requestingValidateFetchPreOrder: false,
          successfulValidateFetchPreOrder: false,
          validatePreOrder: action.value,
        };
      case "FETCH_CODPAYMENT_REQUEST":
        return {
          ...state,
          requestingFetchCodPayment: true,
          successfulFetchCodPayment: false,
          errorFetchCodPayment: false,
        };
      case "FETCH_CODPAYMENT_SUCCESS":
        return {
          ...state,
          errorFetchCodPayment: false,
          requestingFetchCodPayment: false,
          successfulFetchCodPayment: true,
          codPayment: action.value,
        };
      case "FETCH_CODPAYMENT_ERROR":
        return {
          ...state,
          errorFetchCodPayment: true,
          requestingFetchCodPayment: false,
          successfulFetchCodPayment: false,
        };
    default:
      return state;
  }
};

export default orderReducer;
