import io from "socket.io-client";


let socket;
const SOCKET_SERVER_URL = process.env.REACT_APP_REPORT_URL+'/checkoutSocket';

export const initiateCheckoutSocket = (room) => {
    socket = io.connect(SOCKET_SERVER_URL, { query: `room=${room}`});
    console.log(`Connecting socket...${room} ${socket}`)

    if (!socket && !room) return;

    socket.emit('joinRoom', room)
}

export const disconnectCheckoutSocket = () => {
    console.log(`Disconnecting socket...`);
    if (socket) socket.disconnect();
}

export const subscribeToCheckout = (cb, key) => {
    if (!socket) return true;
    socket.on(key, msg => {
        console.log('Websocket event received!')
        return cb(null, msg)
    })
}